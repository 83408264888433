import ApiService from '@/shared/services/api.service';

export class DocumentSeriesTypeService {
    static list(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/document-series-types', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/document-series-types?echo=true', { ...data } )
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/document-series-types/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/document-series-types/${id}`)
                .then(() => resolve())
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async listAutocomplete() {
        const params = {
            params: {
                limit: 500,
            }
        };
        const response = await ApiService.query('/document-series-types', params);
        return response?.data?.data || [];
    }
}
