
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { DocumentSeriesTypeService } from '@/modules/document-series-type/document-series-type-service';

export class DocumentSeriesTypeField {
    static relationToOne(name, label, rules, filterParams, children, options ) {
        return new RelationToOneField(
            name,
            label,
            DocumentSeriesTypeService.listAutocomplete,
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
